<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative overflow-hidden bg-black dark:bg-black">
    <div class="mx-auto max-w-7xl">
      <div class="relative z-10 pb-8 bg-black dark:bg-black sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg class="absolute inset-y-0 right-0 hidden w-48 h-full text-black transform translate-x-1/2 dark:text-black lg:block" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>

        <Popover>
          <div class="relative px-4 pt-6 sm:px-6 lg:px-8">
            <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
              <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div class="flex items-center justify-between w-full md:w-auto">
                  <a href="#">
                    <span class="sr-only">Streamline Tech</span>
                    <img class="w-auto h-12 sm:h-10" src="../assets/StreamlineTechLogo_FULL-White-Blue.png" />
                  </a>
                  <div class="flex items-center -mr-2 md:hidden">
                    <PopoverButton class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span class="sr-only">Open main menu</span>
                      <MenuIcon class="w-6 h-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
              </div>
              <div class="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="font-medium text-gray-500 hover:text-gray-900">{{ item.name }}</a>
                <!--<a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Log in</a> -->
              </div>
            </nav>
          </div>

          <transition enter-active-class="duration-150 ease-out" enter-from-class="scale-95 opacity-0" enter-to-class="scale-100 opacity-100" leave-active-class="duration-100 ease-in" leave-from-class="scale-100 opacity-100" leave-to-class="scale-95 opacity-0">
            <PopoverPanel focus class="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden">
              <div class="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                <div class="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img class="w-auto h-8" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="" />
                  </div>
                  <div class="-mr-2">
                    <PopoverButton class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span class="sr-only">Close main menu</span>
                      <XIcon class="w-6 h-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                <div class="px-2 pt-2 pb-3 space-y-1">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" class="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
                </div>
                <a href="#" class="block w-full px-5 py-3 font-medium text-center text-indigo-600 bg-gray-50 hover:bg-gray-100">
                  Log in
                </a>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <main class="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div class="sm:text-center lg:text-left">
            <h1 class="text-4xl font-extrabold tracking-tight text-gray-100 dark:text-gray-100 sm:text-5xl md:text-6xl">
              <span class="block xl:inline">Streamline your meet</span>
              {{ ' ' }}
              <span class="block text-2xl text-streamline md:text-3xl xl:inline">Make every webcast look like a championship.</span>
            </h1>
            <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              We make it simple for clubs and schools to webcast aquatic events.
            </p>
            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <a href="#Sample" class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white border border-transparent rounded-md bg-streamline-dark hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                  See it in action
                </a>
              </div>

            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-2 lg:w-1/2">
      <img class="w-full h-56 mt-16 sm:h-72 md:h-96"  src="../assets/StartLanes.png" alt="" />
    </div>


    <div class="py-12 bg-gray-200">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="lg:text-center">
            <h2 class="text-base font-semibold tracking-wide uppercase text-streamline-dark">Features</h2>
            <p class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              A better way to stream your meet
            </p>
            <p class="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">
              
            </p>
          </div>

          <div class="mt-10 text-left">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div v-for="feature in features" :key="feature.name" class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center w-12 h-12 text-white rounded-md bg-streamline-dark">
                    <component :is="feature.icon" class="w-6 h-6" aria-hidden="true" />
                  </div>
                  <p class="ml-16 text-lg font-medium leading-6 text-gray-900">{{ feature.name }}</p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  {{ feature.description }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>




      <!-- Timing System section -->
      <div class="mt-16 mb-16">
        <div class="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div class="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
            <div>
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-50 dark:text-gray-50 sm:text-4xl">
                Timing Systems
              </h2>
              <p class="max-w-3xl mt-6 text-lg leading-7 text-gray-300 dark:text-gray-300">
                With Streamline, the timing system is the webcast director.   Streamline supports all the common timing systems in use today. Timers connected to Hy-Tek and Splash are supported. 
              </p>
              <div class="mt-6">
                <a href="#" class="text-base font-medium text-rose-500">
                  
                </a>
              </div>
            </div>
            <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">

              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">
                    <img class="max-h-12" src="../assets/quantum.png" alt="Quantum" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Swiss Timing Quantum</h3>
                  </div>
                </div>
              </div>  

              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">
                    <img class="max-h-12" src="../assets/ares.png" alt="Ares21" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Omega Ares 21</h3>
                  </div>
                </div>
              </div>  

              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">                
                    <img class="max-h-12" src="../assets/Omnisport2000.png" alt="Omnisport 2000" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Daktronics Omnisport 2000 / 2000e</h3>
                  </div>
                </div>                    
              </div>  
              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">                    
                    <img class="max-h-12" src="../assets/Gen7.png" alt="Colorado Gen7" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Colorado Gen7 Serial &amp; Legacy</h3>
                  </div>
                </div>                       
              </div>  
              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">                      
                    <img class="max-h-12" src="../assets/System6.png" alt="Colorado System 6" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Colorado System 6</h3>
                  </div>
                </div>                      
              </div>  
              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">                      
                    <img class="max-h-12" src="../assets/System5.png" alt="Colorado System 5" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Colorado System 5</h3>
                  </div>
                </div>                      
              </div>  
              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">                      
                    <img class="max-h-12" src="../assets/Alge.png" alt="Alge" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Alge SwimTime</h3>
                  </div>
                </div>                      
              </div>  
              <div class="flex justify-center col-span-1 px-4 py-4 bg-gray-800 dark:bg-gray-800">
                <div>
                  <div class="flex items-center justify-center">                      
                    <img class="max-h-12" src="../assets/SST.png" alt="Superior Swim Timing" />
                  </div>
                  <div class="mt-6">
                    <h3 class="text-lg font-medium text-gray-300">Superior Swim Timing</h3>
                  </div>
                </div>                      
              </div>  
            </div>
          </div>
        </div>
      </div>





       <!-- Sport section -->
        <div class="relative py-8 bg-gray-50 sm:py-24 lg:py-16">
          <div class="relative">
            <div class="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p class="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Aquatic Sports Supported
              </p>
              <p class="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Streamline can enhance multiple activies.
              </p>
            </div>
            <div class="grid max-w-md gap-8 px-4 mx-auto mt-12 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              <div class="flex flex-col overflow-hidden rounded-lg shadow-lg">
                <div class="flex-shrink-0">
                  <img class="object-cover w-full" src="../assets/IowaStart.png"  alt="" />
                </div>
                <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                  <div class="flex-1">
                    <a  class="block mt-2">
                      <p class="text-xl font-semibold text-gray-900">
                        Swimming
                      </p>
                      <p class="mt-3 text-base text-gray-500">
                        Swimming is supported on all timing systems.
                      </p>
                    </a>
                  </div>
                </div>
              </div>


              <div class="flex flex-col overflow-hidden rounded-lg shadow-lg">
                <div class="flex-shrink-0">
                  <img class="object-cover w-full " src="../assets/DiveJohn.png"  alt="" />
                </div>
                <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                  <div class="flex-1">
                    <a  class="block mt-2">
                      <p class="text-xl font-semibold text-gray-900">
                        Diving
                      </p>
                      <p class="mt-3 text-base text-gray-500">
                        Diving works with Daktronics, Gen7, Swiss Timing &amp; ISS.
                      </p>
                    </a>
                  </div>
                </div>
              </div>

              <div class="flex flex-col overflow-hidden rounded-lg shadow-lg">
                <div class="flex-shrink-0">
                  <img class="object-cover w-full " src="../assets/AFWaterPolo.png"  alt="" />
                </div>
                <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                  <div class="flex-1">
                    <a  class="block mt-2">
                      <p class="text-xl font-semibold text-gray-900">
                        Water Polo
                      </p>
                      <p class="mt-3 text-base text-gray-500">
                        Water polo is available in several overlay styles.  We support Daktronics and Colorado System 6. Others by request.
                      </p>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div id="Sample" class="relative py-16 bg-black sm:py-24 lg:py-16">
          <div class="relative">
            <div class="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p class="mt-2 text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl">
                Streamline Samples
              </p>
              <p class="mx-auto mt-5 mb-5 text-xl text-gray-500 max-w-prose">
                See Streamline in action.
              </p>
            </div>

            <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
              <li v-for="file in files" :key="file.source" class="relative">
                <div class="block w-full overflow-hidden bg-gray-100">
                  <div class="aspect-w-16 aspect-h-9">
                      <iframe :src="file.source" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
                <p class="block mt-2 text-sm font-medium text-gray-100 truncate pointer-events-none">{{ file.title }}</p>
                <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ file.size }}</p>
              </li>
            </ul>
          </div>
        </div>

      <!-- CTA Section -->
      <div class="bg-white">
        <div class="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span class="block">Ready to get started?</span>
            <span class="block text-streamline bg-clip-text">Get in touch today. </span>
               
          </h2>
          <div class="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <a href="#" class="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-streamline-dark hover:from-purple-700 hover:to-indigo-700">
             sales@streamlinetech.com
            </a>
          </div>
        </div>
      </div>




  </div>

</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon, XIcon, CheckCircleIcon, ClockIcon, VideoCameraIcon, TemplateIcon } from '@heroicons/vue/outline'

const navigation = [

]
//  { name: 'Product', href: '#' },
//  { name: 'Features', href: '#' },

const features = [
  {
    name: 'Fully Automated',
    description:
      'Streamline does not require an operator during the meet.  Graphics and cameras are automatically switched based on data from the timing system.',
    icon: ClockIcon,
  },
  {
    name: 'The Look',
    description:
      'Streamline creates graphics overlays that give your event the same appearance of a TV broadcast.   Lane graphics allow friends and family to easily identify an athlete.',
    icon: TemplateIcon,
  },
  {
    name: 'Any Event',
    description:
      'Streamline is equally at home webcasting an age group meet or powering graphics for a championship meet on network TV.',
    icon: VideoCameraIcon,
  },
  {
    name: 'Simple',
    description:
      'Streamline runs on  desktop or laptop computer. Two cameras and a connection to the scoreboard output of the timing system is all that is needed to go live.',
    icon: CheckCircleIcon,
  },
]



const files = [
  {
   title: 'Long ourse with additional graphics',
   source:
     'https://www.youtube.com/embed//je_FLLOv6Bs?start=6135',
 },
    {
    title: 'Automated long course',
    source:
      'https://www.youtube.com/embed/dOWutBQGO_M?start=1019',
  },
  {
    title: 'Graphics for NBC @ Tyr Pro Mission Viejo',
    source:
      'https://www.youtube.com/embed/r1MYYDB5RIY?start=94',
  },
  {
    title: 'Automated short course',
    source:
      'https://www.youtube.com/embed/uloSe9mmVxY?start=561',
  },
  {
    title: 'Custom graphics',
    source:
      'https://www.youtube.com/embed/JELFNeJnu4U?start=1812',
  },
    {
    title: 'Integration with Splash Meet Manager',
    source:
      'https://www.youtube.com/embed/9jcPJSx9Xi8?start=4132',
  },
  // More files...
]

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      navigation,
      features,
      files,
    }
  },
}
</script>

